// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../../node_modules/bs-fetch/src/Fetch.bs.js";
import * as React from "react";
import * as Random from "../../../../../node_modules/rescript/lib/es6/random.js";
import * as ListUtils from "../../../shared/utils/ListUtils.bs.js";
import * as Caml_array from "../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as $$Notification from "../../../shared/Notification.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as AuthenticityToken from "../../../shared/utils/AuthenticityToken.bs.js";

var FormNotFound = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__FileForm.FormNotFound");

var UnexpectedResponse = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__FileForm.UnexpectedResponse");

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__FileForm";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var defaultTitle = tr(undefined, undefined, "choose_upload");

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            formId: state.formId,
            filename: defaultTitle,
            errors: /* [] */0
          };
  } else if (action.TAG === /* AttachFile */0) {
    return {
            formId: state.formId,
            filename: action._0,
            errors: /* [] */0
          };
  } else {
    return {
            formId: state.formId,
            filename: action._0,
            errors: action._1
          };
  }
}

function handleResponseJSON(filename, send, attachFileCB, json) {
  var id = Json_decode.field("id", Json_decode.string, json);
  Curry._2(attachFileCB, id, filename);
  return Curry._1(send, /* ResetForm */0);
}

function apiErrorTitle(x) {
  if (x.RE_EXN_ID === UnexpectedResponse) {
    return tr(undefined, undefined, "error") + String(x._1);
  } else {
    return tr(undefined, undefined, "smth_went_wrong");
  }
}

function uploadFile(filename, send, attachFileCB, formData) {
  Js_promise.$$catch((function (error) {
          var title = apiErrorTitle(error);
          $$Notification.error(title, tr(undefined, undefined, "please_reload"));
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (json) {
              return Promise.resolve(handleResponseJSON(filename, send, attachFileCB, json));
            }), Js_promise.then_((function (response) {
                  if (response.ok) {
                    return Fetch.$$Response.json(response);
                  } else {
                    return Promise.reject({
                                RE_EXN_ID: UnexpectedResponse,
                                _1: response.status
                              });
                  }
                }), fetch("/timeline_event_files/", Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(formData), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)))));
}

function submitForm(filename, formId, send, addFileAttachmentCB) {
  var element = document.getElementById(formId);
  if (!(element == null)) {
    return uploadFile(filename, send, addFileAttachmentCB, new FormData(element));
  }
  throw {
        RE_EXN_ID: FormNotFound,
        _1: formId,
        Error: new Error()
      };
}

function attachFile(state, send, attachingCB, attachFileCB, preview, $$event) {
  if (preview) {
    return $$Notification.notice(tr(undefined, undefined, "preview_mode"), tr(undefined, undefined, "cannot_attach"));
  }
  var files = $$event.target.files;
  if (files.length === 0) {
    return ;
  }
  var file = Caml_array.get(files, 0);
  var errors = file.size > window.itms.maxUploadFileSize ? ({
        hd: tr(undefined, undefined, "max_file_size"),
        tl: /* [] */0
      }) : /* [] */0;
  if (!ListUtils.isEmpty(errors)) {
    return Curry._1(send, {
                TAG: /* SelectFile */1,
                _0: file.name,
                _1: errors
              });
  }
  var filename = file.name;
  Curry._1(attachingCB, true);
  Curry._1(send, {
        TAG: /* AttachFile */0,
        _0: filename
      });
  submitForm(filename, state.formId, send, attachFileCB);
}

function CoursesCurriculum__FileForm(Props) {
  var attachFileCB = Props.attachFileCB;
  var attachingCB = Props.attachingCB;
  var preview = Props.preview;
  var index = Props.index;
  var match = React.useReducer(reducer, {
        formId: String(Random.$$int(99999)),
        filename: defaultTitle,
        errors: /* [] */0
      });
  var send = match[1];
  var state = match[0];
  return React.createElement("div", undefined, React.createElement("form", {
                  className: "flex items-center flex-wrap",
                  id: state.formId
                }, React.createElement("input", {
                      name: "authenticity_token",
                      type: "hidden",
                      value: AuthenticityToken.fromHead(undefined)
                    }), React.createElement("input", {
                      className: "hidden",
                      id: "attachment_file_" + String(index),
                      multiple: false,
                      name: "file",
                      required: true,
                      type: "file",
                      onChange: (function (param) {
                          return attachFile(state, send, attachingCB, attachFileCB, preview, param);
                        })
                    }), React.createElement("label", {
                      className: "text-center cursor-pointer truncate bg-gray-50 border border-dashed border-gray-600 flex px-4 py-5 items-center font-semibold rounded text-sm hover:text-primary-600 hover:bg-primary-100 hover:border-primary-500 grow",
                      htmlFor: "attachment_file_" + String(index)
                    }, React.createElement("span", {
                          className: "w-full"
                        }, React.createElement("i", {
                              className: "fas fa-upload me-2 text-lg"
                            }), React.createElement("span", {
                              className: "truncate"
                            }, state.filename)))), $$Array.of_list(List.map((function (error) {
                        return React.createElement("div", {
                                    key: error,
                                    className: "mt-2 text-red-700 text-sm"
                                  }, React.createElement("i", {
                                        className: "fas fa-exclamation-circle me-2"
                                      }), React.createElement("span", undefined, error));
                      }), state.errors)), ListUtils.isEmpty(state.errors) ? null : React.createElement("div", {
                    className: "px-4 mt-2 text-sm"
                  }, tr(undefined, undefined, "another_file")));
}

var make = CoursesCurriculum__FileForm;

export {
  FormNotFound ,
  UnexpectedResponse ,
  str ,
  tr ,
  defaultTitle ,
  reducer ,
  handleResponseJSON ,
  apiErrorTitle ,
  uploadFile ,
  submitForm ,
  attachFile ,
  make ,
}
/* defaultTitle Not a pure module */
